@charset 'UTF-8';

/* Icons */
@font-face {
    font-family: 'slick';
    font-weight: normal;
    font-style: normal;
    src: url('../fonts/slick.eot');
    src: url('../fonts/slick.eot?#iefix') format('embedded-opentype'), url('../fonts/slick.woff') format('woff'), url('../fonts/slick.ttf') format('truetype'), url('../fonts/slick.svg#slick') format('svg');
    font-display: swap;
}

/* Slider */
.slick-slider {
	font-size: 16px;
	padding: 0 2rem;
}
.slick-loading {
	.slick-list {
    	background: #fff url('../img/ajax-loader.gif') center center no-repeat;
	}
}
@media screen and (min-width: 992px) {
	.slick-slider {
		padding: 0 1.875rem;
	}
}


/* Arrows */
.slick-prev, .slick-next {
    font-size: 0;
    line-height: 0;

    position: absolute;
    top: 50%;

    display: block;

    width: 2rem;
    height: 2rem;
    padding: 0;
    -webkit-transform: translate(0, -50%);
    -ms-transform: translate(0, -50%);
    transform: translate(0, -50%);

    cursor: pointer;
    z-index: 1;

    color: transparent;
    border: none;
    outline: none;
    background: transparent;
    
    &:before {
    	font-family: 'slick';
	    font-size: 2rem;
	    line-height: 1;
	
		opacity: .75;
	    color: #E2011A;

    	-webkit-font-smoothing: antialiased;
    	-moz-osx-font-smoothing: grayscale;
    }
    
    &:hover, &:focus {
    	color: transparent;
    	outline: none;
    	background: transparent;
    	
    	&:before {
    		opacity: 1;
    	}
    }
    
    &.slick-disabled {
    	&:before {
    		opacity: .25;
    	}
    }
}

/* Arrow: prev */
.slick-prev {
    left: 0;
    right: auto;
    
    &:before {
		content: '←';
	}
}
.inside {
	.slick-prev {
    	left: 1rem;
    	right: auto;
	}
}

[dir='rtl'] {
	.slick-prev {
		left: auto;
		right: 0;
		
		&:before {
			content: '→';
		}
	}
	
	.inside {
		.slick-prev {
			left: auto;
			right: 1rem;
		}
	}
}

/* Arrow: next */
.slick-next {
	left: auto;
	right: 0;
	
	&:before {
		content: '→';
	}
}
.inside {
	.slick-next {
		left: auto;
		right: 1rem;
	}
}
[dir='rtl'] {
	.slick-next {
		left: 0;
		right: auto;
		
		&:before {
			content: '←';
		}
	}
	
	.inside {
		.slick-next {
			left: 1rem;
			right: auto;
		}
	}
}

/* Dots */
.slick-dotted.slick-slider {
    margin-bottom: 2rem;
}

.slick-dots {
	position: absolute;
	bottom: 1.5rem;
	display: block;

    width: 100%;
    padding: 0;
    margin: 0;
    
    list-style: none;
    text-align: center;
    
    li {
    	position: relative;
    	display: inline-block;
	
	    width: 1.25rem;
	    height: 1.25rem;
	    margin: 0 5px;
	    padding: 0;
	    cursor: pointer;
		
		button {
		    font-size: 0;
		    line-height: 0;
		
		    display: block;
		    width: 1.25rem;
		    height: 1.25rem;
		    padding: 5px;
		
		    cursor: pointer;
		    color: transparent;
		    border: 0;
		    outline: none;
		    background: transparent;
		    
		    &:before {
		    	font-family: 'slick';
			    font-size: 6px;
			    line-height: 1.25rem;
			
			    position: absolute;
			    top: 0;
			    left: 0;
			
			    width: 1.25rem;
			    height: 1.25rem;
			
			    content: '•';
			    text-align: center;
			
			    opacity: .25;
			    color: black;
			
			    -webkit-font-smoothing: antialiased;
			    -moz-osx-font-smoothing: grayscale;
		    }
		    
		    &:hover, &:focus {
		    	outline: none;
		    	
		    	&:before {
		    		opacity: 1;
		    	}
		    }
		}
		
		&.slick-active {
			button {
				&:before {
					color: black;
					opacity: .75;
				}
			}
		}
	}
}
